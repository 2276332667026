:root {
    --rdp-cell-size: 40px;
    --rdp-accent-color: #f9e3d4;
    --rdp-color: black;
    --rdp-background-color: #f9e3d4;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid #d57828;
  }

  .rdp {
    margin: 0;
  }

  .rdp-day {
    margin: 1px;
  }
  .rdp-day_selected:not([aria-disabled='true']) {
    color: #d57828!important;
    font-weight: 700;
  }
  
  .vendor-appearance-none {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }